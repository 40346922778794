import {defineStore} from 'pinia';
import {router, usePage} from "@inertiajs/vue3";

export const useAppStore = defineStore('app', {
    state: () => ({
        route: route(),
        page: usePage(),
        showSwitchCompany: false,
    }),
    actions: {
        setCurrentRoute() {
            this.route = route();
        },
        setCurrentPage() {
            this.page = usePage();

            const pageEl = document.querySelector('.page');
            if (pageEl) {
                pageEl.scrollIntoView({behavior: 'instant'});
            }
        },
        setSwitchCompany(toggle) {
            this.showSwitchCompany = toggle;
        },
    },
    getters: {
        showHeader: (state) => {
            return !! state.page.props.design
                && state.page.props.design.variables
                && state.page.props.design.variables.header
                && state.page.props.design.variables.header.show;
        },
        designStyle: (state) => {
            if (! state.page.props.design) {
                return false;
            }

            const vars = state.page.props.design.variables;

            const cssVars = {
                '--header-background-color': vars?.header?.background_colour || null,
                '--header-text-color': vars?.header?.text_colour || null,

                '--side-nav-background-color': vars?.nav?.side?.background_colour || null,
                '--side-nav-border-color': vars?.nav?.side?.border_colour || null,
                '--side-nav-text-color': vars?.nav?.side?.text_colour || null,
                '--side-nav-icon-background-color': vars?.nav?.side?.icon_background_colour || null,
                '--side-nav-icon-text-color': vars?.nav?.side?.icon_text_colour || null,
                '--bottom-nav-background-color': vars?.nav?.bottom?.background_colour || null,
                '--bottom-nav-border-color': vars?.nav?.bottom?.border_colour || null,
                '--bottom-nav-text-color': vars?.nav?.bottom?.text_colour || null,
                '--bottom-nav-icon-background-color': vars?.nav?.bottom?.icon_background_colour || null,
                '--bottom-nav-icon-text-color': vars?.nav?.bottom?.icon_text_colour || null,

                '--home-background-color': vars?.home?.background_colour || null,
                '--home-background-color-darker': vars?.home?.background_colour_darker || null,
                '--home-text-color': vars?.home?.text_colour || null,
                '--home-button-icon-background-color': vars?.home?.button_icon_background_colour || null,
                '--home-button-icon-text-color': vars?.home?.button_icon_text_colour || null,
                '--home-button-text-color': vars?.home?.button_text_colour || null,
                '--home-notification-background-color': vars?.home?.notification_background_colour || null,
                '--home-notification-text-color': vars?.home?.notification_text_colour || null,

                '--sidebar-background-color': vars?.sidebar?.background_colour || null,
                '--sidebar-border-color': vars?.sidebar?.border_colour || null,
                '--sidebar-module-set-background-color': vars?.sidebar?.module_set_background_colour || null,
                '--sidebar-module-set-border-color': vars?.sidebar?.module_set_border_colour || null,
                '--sidebar-module-set-text-color': vars?.sidebar?.module_set_text_colour || null,
                '--sidebar-module-border-color': vars?.sidebar?.module_border_colour || null,
                '--sidebar-module-text-color': vars?.sidebar?.module_text_colour || null,
                '--modules-title-text-color': vars?.modules?.title_text_colour || null,
                '--modules-module-set-background-color': vars?.modules?.module_set_background_colour || null,
                '--modules-module-set-text-color': vars?.modules?.module_set_text_colour || null,
                '--modules-module-background-color': vars?.modules?.module_background_colour || null,
                '--modules-module-text-color': vars?.modules?.module_text_colour || null,
                '--modules-module-focus-border-color': vars?.modules?.module_focus_border_colour || null,
                '--modules-module-icon-background-color': vars?.modules?.module_icon_background_colour || null,
                '--modules-module-icon-text-color': vars?.modules?.module_icon_text_colour || null,

                '--module-green-audio-icon-background-color': vars?.module?.green?.audio_icon_background_colour || null,
                '--module-green-audio-icon-text-color': vars?.module?.green?.audio_icon_text_colour || null,
                '--module-green-header-background-color': vars?.module?.green?.header_background_colour || null,
                '--module-green-header-text-color': vars?.module?.green?.header_text_colour || null,
                '--module-green-content-heading-background-color': vars?.module?.green?.content_heading_background_colour || null,
                '--module-green-content-heading-text-color': vars?.module?.green?.content_heading_text_colour || null,
                '--module-green-content-text-color': vars?.module?.green?.content_text_colour || null,
                '--module-green-content-accent-color': vars?.module?.green?.content_accent_colour || null,
                '--module-green-content-info-exclamation-icon-background-color': vars?.module?.green?.content_info_exclamation_icon_background_colour || null,
                '--module-green-content-info-exclamation-icon-text-color': vars?.module?.green?.content_info_exclamation_icon_text_colour || null,
                '--module-green-content-info-exclamation-background-color': vars?.module?.green?.content_info_exclamation_background_colour || null,
                '--module-green-content-info-exclamation-text-color': vars?.module?.green?.content_info_exclamation_text_colour || null,
                '--module-green-content-info-exclamation-accent-color': vars?.module?.green?.content_info_exclamation_accent_colour || null,
                '--module-green-content-info-bulb-icon-background-color': vars?.module?.green?.content_info_bulb_icon_background_colour || null,
                '--module-green-content-info-bulb-icon-text-color': vars?.module?.green?.content_info_bulb_icon_text_colour || null,
                '--module-green-content-info-bulb-background-color': vars?.module?.green?.content_info_bulb_background_colour || null,
                '--module-green-content-info-bulb-text-color': vars?.module?.green?.content_info_bulb_text_colour || null,
                '--module-green-content-info-bulb-accent-color': vars?.module?.green?.content_info_bulb_accent_colour || null,
                '--module-green-button-background-color': vars?.module?.green?.button_background_colour || null,
                '--module-green-button-text-color': vars?.module?.green?.button_text_colour || null,
                '--module-green-button-focus-border-color': vars?.module?.green?.button_focus_border_colour || null,

                '--module-yellow-audio-icon-background-color': vars?.module?.yellow?.audio_icon_background_colour || null,
                '--module-yellow-audio-icon-text-color': vars?.module?.yellow?.audio_icon_text_colour || null,
                '--module-yellow-header-background-color': vars?.module?.yellow?.header_background_colour || null,
                '--module-yellow-header-text-color': vars?.module?.yellow?.header_text_colour || null,
                '--module-yellow-content-heading-background-color': vars?.module?.yellow?.content_heading_background_colour || null,
                '--module-yellow-content-heading-text-color': vars?.module?.yellow?.content_heading_text_colour || null,
                '--module-yellow-content-text-color': vars?.module?.yellow?.content_text_colour || null,
                '--module-yellow-content-accent-color': vars?.module?.yellow?.content_accent_colour || null,
                '--module-yellow-content-info-exclamation-icon-background-color': vars?.module?.yellow?.content_info_exclamation_icon_background_colour || null,
                '--module-yellow-content-info-exclamation-icon-text-color': vars?.module?.yellow?.content_info_exclamation_icon_text_colour || null,
                '--module-yellow-content-info-exclamation-background-color': vars?.module?.yellow?.content_info_exclamation_background_colour || null,
                '--module-yellow-content-info-exclamation-text-color': vars?.module?.yellow?.content_info_exclamation_text_colour || null,
                '--module-yellow-content-info-exclamation-accent-color': vars?.module?.yellow?.content_info_exclamation_accent_colour || null,
                '--module-yellow-content-info-bulb-icon-background-color': vars?.module?.yellow?.content_info_bulb_icon_background_colour || null,
                '--module-yellow-content-info-bulb-icon-text-color': vars?.module?.yellow?.content_info_bulb_icon_text_colour || null,
                '--module-yellow-content-info-bulb-background-color': vars?.module?.yellow?.content_info_bulb_background_colour || null,
                '--module-yellow-content-info-bulb-text-color': vars?.module?.yellow?.content_info_bulb_text_colour || null,
                '--module-yellow-content-info-bulb-accent-color': vars?.module?.yellow?.content_info_bulb_accent_colour || null,
                '--module-yellow-button-background-color': vars?.module?.yellow?.button_background_colour || null,
                '--module-yellow-button-text-color': vars?.module?.yellow?.button_text_colour || null,
                '--module-yellow-button-focus-border-color': vars?.module?.yellow?.button_focus_border_colour || null,

                '--module-burgundy-audio-icon-background-color': vars?.module?.burgundy?.audio_icon_background_colour || null,
                '--module-burgundy-audio-icon-text-color': vars?.module?.burgundy?.audio_icon_text_colour || null,
                '--module-burgundy-header-background-color': vars?.module?.burgundy?.header_background_colour || null,
                '--module-burgundy-header-text-color': vars?.module?.burgundy?.header_text_colour || null,
                '--module-burgundy-content-heading-background-color': vars?.module?.burgundy?.content_heading_background_colour || null,
                '--module-burgundy-content-heading-text-color': vars?.module?.burgundy?.content_heading_text_colour || null,
                '--module-burgundy-content-text-color': vars?.module?.burgundy?.content_text_colour || null,
                '--module-burgundy-content-accent-color': vars?.module?.burgundy?.content_accent_colour || null,
                '--module-burgundy-content-info-exclamation-icon-background-color': vars?.module?.burgundy?.content_info_exclamation_icon_background_colour || null,
                '--module-burgundy-content-info-exclamation-icon-text-color': vars?.module?.burgundy?.content_info_exclamation_icon_text_colour || null,
                '--module-burgundy-content-info-exclamation-background-color': vars?.module?.burgundy?.content_info_exclamation_background_colour || null,
                '--module-burgundy-content-info-exclamation-text-color': vars?.module?.burgundy?.content_info_exclamation_text_colour || null,
                '--module-burgundy-content-info-exclamation-accent-color': vars?.module?.burgundy?.content_info_exclamation_accent_colour || null,
                '--module-burgundy-content-info-bulb-icon-background-color': vars?.module?.burgundy?.content_info_bulb_icon_background_colour || null,
                '--module-burgundy-content-info-bulb-icon-text-color': vars?.module?.burgundy?.content_info_bulb_icon_text_colour || null,
                '--module-burgundy-content-info-bulb-background-color': vars?.module?.burgundy?.content_info_bulb_background_colour || null,
                '--module-burgundy-content-info-bulb-text-color': vars?.module?.burgundy?.content_info_bulb_text_colour || null,
                '--module-burgundy-content-info-bulb-accent-color': vars?.module?.burgundy?.content_info_bulb_accent_colour || null,
                '--module-burgundy-button-background-color': vars?.module?.burgundy?.button_background_colour || null,
                '--module-burgundy-button-text-color': vars?.module?.burgundy?.button_text_colour || null,
                '--module-burgundy-button-focus-border-color': vars?.module?.burgundy?.button_focus_border_colour || null,

                '--module-assessment-green-title-text-color': vars?.module_assessment?.green?.title_text_colour || null,
                '--module-assessment-green-background-color': vars?.module_assessment?.green?.background_colour || null,
                '--module-assessment-green-button-background-color': vars?.module_assessment?.green?.button_background_colour || null,
                '--module-assessment-green-button-text-color': vars?.module_assessment?.green?.button_text_colour || null,
                '--module-assessment-green-question-text-color': vars?.module_assessment?.green?.question_text_colour || null,
                '--module-assessment-green-question-answer-background-color': vars?.module_assessment?.green?.question_answer_background_colour || null,
                '--module-assessment-green-question-answer-text-color': vars?.module_assessment?.green?.question_answer_text_colour || null,
                '--module-assessment-green-question-answer-focus-border-color': vars?.module_assessment?.green?.question_answer_focus_border_colour || null,
                '--module-assessment-green-question-statement-correct-text-color': vars?.module_assessment?.green?.question_statement_correct_text_colour || null,
                '--module-assessment-green-question-statement-partially-correct-text-color': vars?.module_assessment?.green?.question_statement_partially_correct_text_colour || null,
                '--module-assessment-green-question-statement-wrong-text-color': vars?.module_assessment?.green?.question_statement_wrong_text_colour || null,

                '--module-assessment-yellow-title-text-color': vars?.module_assessment?.yellow?.title_text_colour || null,
                '--module-assessment-yellow-background-color': vars?.module_assessment?.yellow?.background_colour || null,
                '--module-assessment-yellow-button-background-color': vars?.module_assessment?.yellow?.button_background_colour || null,
                '--module-assessment-yellow-button-text-color': vars?.module_assessment?.yellow?.button_text_colour || null,
                '--module-assessment-yellow-question-text-color': vars?.module_assessment?.yellow?.question_text_colour || null,
                '--module-assessment-yellow-question-answer-background-color': vars?.module_assessment?.yellow?.question_answer_background_colour || null,
                '--module-assessment-yellow-question-answer-text-color': vars?.module_assessment?.yellow?.question_answer_text_colour || null,
                '--module-assessment-yellow-question-answer-focus-border-color': vars?.module_assessment?.yellow?.question_answer_focus_border_colour || null,
                '--module-assessment-yellow-question-statement-correct-text-color': vars?.module_assessment?.yellow?.question_statement_correct_text_colour || null,
                '--module-assessment-yellow-question-statement-partially-correct-text-color': vars?.module_assessment?.yellow?.question_statement_partially_correct_text_colour || null,
                '--module-assessment-yellow-question-statement-wrong-text-color': vars?.module_assessment?.yellow?.question_statement_wrong_text_colour || null,

                '--module-assessment-burgundy-title-text-color': vars?.module_assessment?.burgundy?.title_text_colour || null,
                '--module-assessment-burgundy-background-color': vars?.module_assessment?.burgundy?.background_colour || null,
                '--module-assessment-burgundy-button-background-color': vars?.module_assessment?.burgundy?.button_background_colour || null,
                '--module-assessment-burgundy-button-text-color': vars?.module_assessment?.burgundy?.button_text_colour || null,
                '--module-assessment-burgundy-question-text-color': vars?.module_assessment?.burgundy?.question_text_colour || null,
                '--module-assessment-burgundy-question-answer-background-color': vars?.module_assessment?.burgundy?.question_answer_background_colour || null,
                '--module-assessment-burgundy-question-answer-text-color': vars?.module_assessment?.burgundy?.question_answer_text_colour || null,
                '--module-assessment-burgundy-question-answer-focus-border-color': vars?.module_assessment?.burgundy?.question_answer_focus_border_colour || null,
                '--module-assessment-burgundy-question-statement-correct-text-color': vars?.module_assessment?.burgundy?.question_statement_correct_text_colour || null,
                '--module-assessment-burgundy-question-statement-partially-correct-text-color': vars?.module_assessment?.burgundy?.question_statement_partially_correct_text_colour || null,
                '--module-assessment-burgundy-question-statement-wrong-text-color': vars?.module_assessment?.burgundy?.question_statement_wrong_text_colour || null,

                '--assessments-title-text-color': vars?.assessments?.title_text_colour || null,
                '--assessments-assessment-background-color': vars?.assessments?.assessment_background_colour || null,
                '--assessments-assessment-text-color': vars?.assessments?.assessment_text_colour || null,
                '--assessments-assessment-focus-border-color': vars?.assessments?.assessment_focus_border_colour || null,
                '--assessments-assessment-icon-color': vars?.assessments?.assessment_icon_colour || null,
                '--assessments-assessment-complete-icon-color': vars?.assessments?.assessment_complete_icon_colour || null,
                '--assessments-assessment-score-text-color': vars?.assessments?.assessment_score_text_colour || null,

                '--assessment-title-text-color': vars?.assessment?.title_text_colour || null,
                '--assessment-background-color': vars?.assessment?.background_colour || null,
                '--assessment-button-background-color': vars?.assessment?.button_background_colour || null,
                '--assessment-button-text-color': vars?.assessment?.button_text_colour || null,
                '--assessment-question-text-color': vars?.assessment?.question_text_colour || null,
                '--assessment-question-answer-background-color': vars?.assessment?.question_answer_background_colour || null,
                '--assessment-question-answer-text-color': vars?.assessment?.question_answer_text_colour || null,
                '--assessment-question-answer-focus-border-color': vars?.assessment?.question_answer_focus_border_colour || null,
                '--assessment-question-statement-correct-text-color': vars?.assessment?.question_statement_correct_text_colour || null,
                '--assessment-question-statement-partially-correct-text-color': vars?.assessment?.question_statement_partially_correct_text_colour || null,
                '--assessment-question-statement-wrong-text-color': vars?.assessment?.question_statement_wrong_text_colour || null,

                '--profile-title-text-color': vars?.profile?.title_text_colour || null,
                '--profile-user-text-color': vars?.profile?.user_text_colour || null,
                '--profile-messages-icon-color': vars?.profile?.messages_icon_colour || null,
                '--profile-messages-icon-hover-color': vars?.profile?.messages_icon_hover_colour || null,
                '--profile-messages-icon-pip-background-color': vars?.profile?.messages_icon_pip_background_colour || null,
                '--profile-messages-icon-pip-text-color': vars?.profile?.messages_icon_pip_text_colour || null,
                '--profile-score-dial-text-color': vars?.profile?.score_dial_text_colour || null,
                '--profile-score-dial-text-bronze-color': vars?.profile?.score_dial_text_bronze_colour || null,
                '--profile-score-dial-text-silver-color': vars?.profile?.score_dial_text_silver_colour || null,
                '--profile-score-dial-text-gold-color': vars?.profile?.score_dial_text_gold_colour || null,
                '--profile-score-dial-circle-color': vars?.profile?.score_dial_circle_colour || null,
                '--profile-score-dial-circle-bronze-color': vars?.profile?.score_dial_circle_bronze_colour || null,
                '--profile-score-dial-circle-silver-color': vars?.profile?.score_dial_circle_silver_colour || null,
                '--profile-score-dial-circle-gold-color': vars?.profile?.score_dial_circle_gold_colour || null,
                '--profile-score-title-text-color': vars?.profile?.score_title_text_colour || null,
                '--profile-score-text-color': vars?.profile?.score_text_colour || null,
                '--profile-nav-border-color': vars?.profile?.nav_border_colour || null,
                '--profile-nav-text-color': vars?.profile?.nav_text_colour || null,
                '--profile-nav-icon-background-color': vars?.profile?.nav_icon_background_colour || null,

                '--profile-details-title-text-color': vars?.profile_details?.title_text_colour || null,
                '--profile-details-label-text-color': vars?.profile_details?.label_text_colour || null,
                '--profile-details-field-background-color': vars?.profile_details?.field_background_colour || null,
                '--profile-details-field-text-color': vars?.profile_details?.field_text_colour || null,
                '--profile-details-field-border-color': vars?.profile_details?.field_border_colour || null,
                '--profile-details-field-focus-border-color': vars?.profile_details?.field_focus_border_colour || null,
                '--profile-details-button-background-color': vars?.profile_details?.button_background_colour || null,
                '--profile-details-button-text-color': vars?.profile_details?.button_text_colour || null,
                '--profile-details-button-focus-border-color': vars?.profile_details?.button_focus_border_colour || null,

                '--messages-title-text-color': vars?.messages?.title_text_colour || null,
                '--messages-message-background-color': vars?.messages?.message_background_colour || null,
                '--messages-message-text-color': vars?.messages?.message_text_colour || null,
                '--messages-message-focus-border-color': vars?.messages?.message_focus_border_colour || null,
                '--messages-message-icon-color': vars?.messages?.message_icon_colour || null,
                '--messages-button-background-color': vars?.messages?.button_background_colour || null,
                '--messages-button-text-color': vars?.messages?.button_text_colour || null,
                '--messages-button-focus-border-color': vars?.messages?.button_focus_border_colour || null,

                '--message-title-text-color': vars?.message?.title_text_colour || null,
                '--message-content-text-color': vars?.message?.content_text_colour || null,
                '--message-content-accent-color': vars?.message?.content_accent_colour || null,
                '--message-content-heading-background-color': vars?.message?.content_heading_background_colour || null,
                '--message-content-heading-text-color': vars?.message?.content_heading_text_colour || null,
                '--message-button-background-color': vars?.message?.button_background_colour || null,
                '--message-button-text-color': vars?.message?.button_text_colour || null,
                '--message-button-focus-border-color': vars?.message?.button_focus_border_colour || null,
            };

            let css = '';
            Object.keys(cssVars).forEach(key => {
                if (cssVars[key]) {
                    css += key + ': ' + cssVars[key] + ';' + "\n";
                }
            });

            let style = state.page.props.design.css || '';

            if (css !== '') {
                style += "\n:root {\n" + css + "\n}\n";
            }

            return style;
        },
    },
})

if (typeof window !== 'undefined') {
    router.on('navigate', () => {
        const app = useAppStore();
        app.setCurrentRoute();
        app.setCurrentPage();
    });
}
